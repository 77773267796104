import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "./LocalizedLink";
import { BiChevronRight } from "react-icons/bi";
import { LocaleContext } from "./Layout";

const Event = () => {
  const {
    site: {
      siteMetadata: { events },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            events {
              text {
                en
                zh
              }
              href
            }
          }
        }
      }
    `
  );
  const { locale } = React.useContext(LocaleContext);
  return events.length > 0 ? (
    <div className="flex flex-col bg-logoYellow-light py-2 px-6 text-xs sm:text-sm">
      {events.map(({ text, href }, i) => (
        <div className="mx-auto" key={i}>
          {text[locale][0]}
          {href.charAt(0) === "/" ? (
            <Link to={href} className="inline-flex items-center underline">
              {text[locale][1]} <BiChevronRight className="w-5 h-5" />
            </Link>
          ) : (
            <a
              href={href}
              className="inline-flex items-center underline"
              target="_blank"
              rel="noreferrer"
            >
              {text[locale][1]} <BiChevronRight className="w-5 h-5" />
            </a>
          )}
        </div>
      ))}
    </div>
  ) : null;
};

export default Event;
